<template>
    <div style="width:100%;height:100%;">
        <EZUIKitJs v-if="cameraObj.deviceManufacturer == 'HK'" :msg="'video'+1" :attribute="environmentalAttribute" :flv="cameraObj.videoUrl" />
        <EZUIKitJs2 v-if="cameraObj.deviceManufacturer == 'DH'" :msg="'video'+2"  :attribute="environmentalAttribute" :flv="cameraObj.videoUrl"/>
    </div>
</template>

<script>
import EZUIKitJs from '../../components/EZUIKitJs.vue'
import EZUIKitJs2 from '../../components/EZUIKitJs2.vue'
export default {
  components: {
    EZUIKitJs,
    EZUIKitJs2,
  },
  data() {
      return {
          cameraObj: {},
          environmentalAttribute: {
            width: window.innerWidth,
            height: window.innerHeight
          },
      }
  },
  mounted () {
      this.cameraList();
  },
  methods: {
      cameraList () {
          this.qa.sjDeviceQueryDeviceBydeviceAddr({deviceAddr: this.$route.query.deviceAddr}).then((res) => {
              console.log(res)
              this.cameraObj = res.data
          })
      },
  },
}
</script>

<style scoped>

</style>

